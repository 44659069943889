// colorUtils.js

function hexToRgb(hex) {
    // Eliminar el "#" si está presente
    hex = hex.replace(/^#/, '');
  
    // Convertir a RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
  
    return { r, g, b };
  }
  
  export function lightenColor(color, percent) {
    // Convertir el color a RGB
    let { r, g, b } = hexToRgb(color);
  
    // Ajustar el brillo
    r = Math.floor(r + (255 - r) * percent);
    g = Math.floor(g + (255 - g) * percent);
    b = Math.floor(b + (255 - b) * percent);
  
    // Asegurar que los valores estén en el rango correcto
    r = Math.min(r, 255);
    g = Math.min(g, 255);
    b = Math.min(b, 255);
  
    // Convertir de nuevo a hexadecimal
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  