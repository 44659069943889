// MapContext.js
import React, { createContext, useContext, useState } from 'react';

// Creamos el contexto para el mapa
const MapContext = createContext();

// Hook personalizado para acceder al contexto del mapa
export const useMap = () => useContext(MapContext);

// Proveedor del contexto del mapa
export const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null); // Estado para almacenar el valor del mapa

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};
