import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, Typography, Grid, IconButton, SliderMark } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer.js';
import { Cluster, GeoTIFF, Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON'; // Agregar esta línea
import { Vector as VectorLayer } from 'ol/layer';
import { Style, Icon, Stroke, Text } from 'ol/style';
import Fill from 'ol/style/Fill';
import Overlay from 'ol/Overlay';
import IconLayer from './IconLayer';
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer.js';
import { lightenColor } from './ext/ColorUtils'
import Select from 'ol/interaction/Select';
import { MultiLineString, LineString } from 'ol/geom';
import { getTransform } from 'ol/proj.js';
import TopoJSON from 'ol/format/TopoJSON.js';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import { createXYZ } from 'ol/tilegrid';
const GreenAccordion = ({ map, items }) => {
  const [addedLayers, setAddedLayers] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [expanded, setExpanded] = useState({
    panel1: true,  // Panel 1 expandido por defecto
    panel2: true,  // Panel 2 expandido por defecto
    panel3: false  // Panel 3 cerrado por defecto
  });
  const [isDisabled, setIsDisabled] = useState(false);
 
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: isExpanded ? panel : false
    }));
  };
  const style = {
    'stroke-color': ['*', ['get', 'COLOR'], [245, 212, 136]],
    'stroke-width': 2,
    'stroke-offset': -1,
    'fill-color': ['*', ['get', 'COLOR'], [245, 212, 136, 0.6]],
  };

  class WebGLLayer extends Layer {
    createRenderer() {
      return new WebGLVectorLayerRenderer(this, {
        style,
      });
    }
  }

  const style2 = {
    'stroke-color': ['*', ['get', 'COLOR'], [39, 103, 136]],
    'stroke-width': 2,
    'stroke-offset': -1,
    'fill-color': ['*', ['get', 'COLOR'], [95, 152, 212, 0.7]],
  };

  class WebGLLayer2 extends Layer {
    createRenderer() {
      return new WebGLVectorLayerRenderer(this, {
        style2,
      });
    }
  }


  const addMVTLayer = (item) => {
    const url = item.url;
    const styleSimple = new Style({
      fill: new Fill({
       color: '#d7d7a8'
      }),
      stroke: new Stroke({
          color: '#319FD3',
        width: 1
      })
    });

    const styleFunction = (feature) => {
      const propertyValue = feature.get('cod_rang');
      const labelValue = feature.get('NOM_DIST');
      // Define estilos basados en el valor del atributo
      let color;
      if (propertyValue =='5') {
        color = 'rgba(255, 0, 0, 0.8)';
      } else if (propertyValue =='4') {
        color = 'rgba(245, 122, 0, 0.8)';
      } else if (propertyValue =='3') {
        color = 'rgba(255, 201, 28, 0.8)';
      } else if (propertyValue =='2') {
        color = 'rgba(255, 255, 115, 0.8)';
      }else if (propertyValue =='1') {
        color = 'rgba(211, 255, 190, 0.8)';
      }else if (propertyValue =='9') {
        color = 'rgba(225, 225, 225, 0.8)';
      }
  
      return new Style({
        fill: new Fill({ color: color }),
        stroke: new Stroke({ color: 'black', width: 1 }),
        text: new Text({
          text: labelValue.toString(),
          font: '12px Calibri,sans-serif',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({
            color: '#fff',
            width: 3
          }),
          offsetY: -15  // Ajusta este valor para posicionar la etiqueta
        })
      });
    };
    const vectorLayerPolygon = new VectorTile({
      style: styleFunction,
      declutter: true,
      source: new VectorTileSource({
        tilePixelRatio: 1,
        tileGrid: createXYZ({ maxZoom: 19 }),
        format: new MVT(),
        url: url
      })
    });

    vectorLayerPolygon.set('id_data', item.id);
    map.addLayer(vectorLayerPolygon);
    setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPolygon }]);
  }

  const addGeoJSONLayerPy = (item) => {

    function stringDivider(str, width, spaceReplacer) {
      if (str.length > width) {
        let p = width;
        while (p > 0 && str[p] != ' ' && str[p] != '-') {
          p--;
        }
        if (p > 0) {
          let left;
          if (str.substring(p, p + 1) == '-') {
            left = str.substring(0, p + 1);
          } else {
            left = str.substring(0, p);
          }
          const right = str.substring(p + 1);
          return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
        }
      }
      return str;
    }

    const getText = function (feature, resolution) {
      const type = 'wrap';
      const maxResolution = 2500;
      let text = 'label';

      if (resolution > maxResolution) {
        text = '';
      } else if (type == 'hide') {
        text = '';
      } else if (type == 'shorten') {
        text = text.trunc(12);
      } else if (
        type == 'wrap'
      ) {
        text = stringDivider(text, 16, '\n');
      }

      return text;
    };

    const createTextStyle = function (feature, resolution) {
      return new Text({
        textAlign: 'center',
        font: 'bold 12px sans-serif',
        text: feature.getProperties().codigo,
        fill: new Fill({ color: '#16a518' }),
        stroke: new Stroke({ color: '#ffffff', width: 3 }),
      });
    };

    function polygonStyleFunction(feature, resolution) {
      return new Style({
        text: createTextStyle(feature, resolution)
      });
    }
    const url = item.url;
    const getColorWithOpacity = (color, alpha) => {

      const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));

      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
 
    var polygonStyle2 = new Style({
      fill: new Fill({
        color: getColorWithOpacity(item.style.color, 0.5)
      }),
      stroke: new Stroke({
        color: item.style.color,
        width: 5
      }),

    });

    const vectorSourcePoint = new VectorSource({
      url: item.url,
      format: item.topo ? new TopoJSON() : new GeoJSON(),

    });

    if (item.webgl) {
      let vectorLayerPolygon;
    

      vectorLayerPolygon = new WebGLLayer({
        source: vectorSourcePoint,
        style: {
          'stroke-color': 'rgba(12, 255, 255, 1)',
          'stroke-width': 12,
          'fill-color': 'rgba(255, 0, 0, 1)'
        },
        zIndex: 0
      });
    
      vectorLayerPolygon.set('id_data', item.id);
      map.addLayer(vectorLayerPolygon);
      setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPolygon }]);

      vectorSourcePoint.once('change', function () {
        if (vectorSourcePoint.getState() === 'ready') {
          vectorSourcePoint.getFeatures().forEach(function (feature, index) {
          });
        }
      });


    }
    else {
      console.log('as45');
      if(item.withLabel){
        const vectorLayerPolygon = new VectorLayer({
          source: vectorSourcePoint,
          style: polygonStyleFunction,
          zIndex: 0
        });
        let vectorLayerPolygon2;
        if(item.style.topo_style){
          console.log('12');
           vectorLayerPolygon2 = new WebGLLayer2({
            source: vectorSourcePoint,
            style: {
              'stroke-color': 'rgba(12, 255, 255, 1)',
              'stroke-width': 12,
              'fill-color': 'rgba(255, 0, 0, 1)'
            },
            zIndex: 0
          });
        }
        else{
          console.log('1244');
         vectorLayerPolygon2 = new WebGLLayer({
          source: vectorSourcePoint,
          style: {
            'stroke-color': 'rgba(12, 255, 255, 1)',
            'stroke-width': 12,
            'fill-color': 'rgba(255, 0, 0, 1)'
          },
          zIndex: 0
        });
      }
        vectorLayerPolygon.set('id_data', item.id);
        vectorLayerPolygon2.set('id_data', item.id);
        map.addLayer(vectorLayerPolygon2);
        setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPolygon2 }]);
  
        map.addLayer(vectorLayerPolygon);
        setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPolygon }]);
  
      }
      else{
        console.log('as4544');
        const vectorLayerPolygon = new VectorLayer({
          source: vectorSourcePoint,
          style: polygonStyle2,
          zIndex: 0
        });
        vectorLayerPolygon.set('id_data', item.id);
        map.addLayer(vectorLayerPolygon);
        setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPolygon }]);
      }
    
    }

  }

  const addGeoJSONLayerL = (item) => {

    var lineStyleWithNegativeOffset = new Style({
      stroke: new Stroke({
        color: item.style.color,
        width: 2,
        lineJoin: 'bevel',
        lineCap: 'square'
      }),
      geometry: function (feature) {
        const geometry = feature.getGeometry();

        const coordinates = geometry.getCoordinates();
        const firstPoint = coordinates[0];
        const lastPoint = coordinates[coordinates.length - 1];

        // Calcular el ángulo entre los dos puntos
        const angle = Math.atan2(lastPoint[1] - firstPoint[1], lastPoint[0] - firstPoint[0]);

        // Calcular el desplazamiento perpendicular
        const dx = 2.5 * Math.cos(angle + Math.PI / 2);
        const dy = 2.5 * Math.sin(angle + Math.PI / 2);

        // Crear una nueva geometría con los puntos desplazados
        const newCoordinates = coordinates.map(coord => [coord[0] + dx, coord[1] + dy]);

        const newGeometry = new LineString(newCoordinates);
        return newGeometry;
      }

    });
    var lineStyleWithPositiveOffset = new Style({
      stroke: new Stroke({
        color: item.style.color,
        width: 2,
        lineJoin: 'bevel',
        lineCap: 'square'
      }),
      geometry: function (feature) {
        const geometry = feature.getGeometry();

        const coordinates = geometry.getCoordinates();
        const firstPoint = coordinates[0];
        const lastPoint = coordinates[coordinates.length - 1];

        // Calcular el ángulo entre los dos puntos
        const angle = Math.atan2(lastPoint[1] - firstPoint[1], lastPoint[0] - firstPoint[0]);

        // Calcular el desplazamiento perpendicular
        const dx = -2 * Math.cos(angle + Math.PI / 2);
        const dy = -2 * Math.sin(angle + Math.PI / 2);

        // Crear una nueva geometría con los puntos desplazados
        const newCoordinates = coordinates.map(coord => [coord[0] + dx, coord[1] + dy]);

        const newGeometry = new LineString(newCoordinates);
        return newGeometry;
      }

    });
    const backgroundStyle = new Style({
      stroke: new Stroke({
        color: 'white', // Color del fondo
        width: 3 // Ancho de la línea
      })
    });

    const backgroundStyleFill = new Style({
      stroke: new Stroke({
        color: item.style.color2 ? item.style.color2 : 'black', // Color del fondo
        width: 5.4,
        lineCap: 'square',
        lineJoin: 'bevel'
      })
    });
    var lineStyleFill = new Style({
      stroke: new Stroke({
        color: item.style.color ? item.style.color : '#FFFFFF', // Color de la línea
        width: 3,
        lineCap: 'square',
        lineJoin: 'bevel'
      }),
    });
    // Estilo para la línea punteada
    const dashedStyle = new Style({
      stroke: new Stroke({
        color: item.style.color, // Color de la línea punteada
        lineCap: 'square',
        lineJoin: 'bevel',
        width: 3, // Ancho de la línea
        lineDash: [7, 11] // Patrón de línea punteada: 10 píxeles de línea, 5 píxeles de espacio
      })
    });

    const url = item.url;
    var lineStyle = new Style({
      stroke: new Stroke({
        color: item.style.color ? item.style.color : '#FFFFFF', // Color de la línea
        width: 6,
        lineCap: 'round'
      }),
    });


    const vectorSourcePoint = new VectorSource({
      url: item.url,
      format: item.topo ? new TopoJSON() : new GeoJSON()
    });

    let defaul_style = [];

    switch (item.style.tipo) {
      case 'dash':
        defaul_style = [backgroundStyle, dashedStyle];
        break;
      case 'dash2':
        defaul_style = [dashedStyle];
        break;
      case 'fill':
        defaul_style = [backgroundStyleFill, lineStyleFill];
        break;
      case 'offset':
        defaul_style = [lineStyleWithNegativeOffset, lineStyleWithPositiveOffset];
        break;
      default:
        defaul_style = [lineStyle];
        break;
    }




    const vectorLayerPoint = new VectorLayer({
      source: vectorSourcePoint,
      style: defaul_style,

      zIndex: 1
    });
    vectorLayerPoint.set('id_data', item.id);
    map.addLayer(vectorLayerPoint);
    setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPoint }]);
  }
  const addGeoJSONLayerP = (item) => {
    const url = item.url;
    const svg = `   <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    
    <path d="M23.147 12C23.147 18.0751 18.2221 23 12.147 23C6.07183 23 1.14697 18.0751 1.14697 12C1.14697 5.92487 6.07184 1 12.147 1C18.2221 1 23.147 5.92487 23.147 12Z"
     fill='${lightenColor(item.style.color, 0.4)}' stroke='${item.style.color}' stroke-width="2"/>
    </svg>
`;
    // const markerStyle = new Style({
    //   image: new Icon({
    //     src: item.icon ? item.icon : `data:image/svg+xml;base64,${btoa(svg)}`,
    //     scale: 1
    //   })
    // });

    const vectorSourcePoint = new VectorSource({
      url: item.url,
      format: new GeoJSON(),
      zIndex: 2,
    });



    if(!item.cluster){
    vectorSourcePoint.once('change', function () {
      if (vectorSourcePoint.getState() === 'ready') {
        vectorSourcePoint.getFeatures().forEach(function (feature) {
          // feature.setStyle(markerStyle);
          const labelElement = document.createElement('div');
          labelElement.textContent = feature.getProperties().Name;
          labelElement.style.fontSize = "16px";
          labelElement.style.fontWeight = "bold";
          labelElement.style.color = item.style.color;
          labelElement.style.textShadow = "-2px -2px 2px #ffffff, 2px 2px 2px #ffffff, -2px 2px 2px #ffffff, 2px -2px 2px #ffffff";
          labelElement.style.stroke = 'white';
          labelElement.style.strokeWidth = '2';
          labelElement.style.pointerEvents = "none";
          // Obtener la posición del marcador
          const coordinates = feature.getGeometry().getCoordinates();

            // Crear un contenedor para el SVG animado
            const markerElement = document.createElement('img');
            markerElement.src = item.icon ? item.icon : `data:image/svg+xml;base64,${btoa(svg)}`; // Cambia a la ruta de tu archivo SVG
            markerElement.style.position = 'absolute';
            markerElement.style.transform = 'translate(-50%, -50%)'; // Centrar el SVG
            markerElement.style.pointerEvents = 'none'; // Evitar interferencias con eventos del mapa

        // Crear un Overlay para el marcador animado
        const markerOverlay2 = new Overlay({
          position: coordinates,
          element: markerElement,
          stopEvent: false,
          zIndex: 1,
        });

        map.addOverlay(markerOverlay2);
        feature.set('overlay', markerOverlay2);



          // Posicionar la etiqueta encima del marcador
          const markerOverlay = new Overlay({
            position: coordinates,
            element: labelElement,
            stopEvent: false,
            zIndex: 1,
            offset: [-55, 10] // Ajustar el offset para alinear la etiqueta
          });

          // Agregar la etiqueta al mapa
          // feature.set('overlay', markerOverlay);

          const maxZoomToShowLabels = 500000;
          const view = map.getView();

          // Obtener la resolución actual del mapa
          const resolution = view.getResolution();

          // Obtener la unidad de distancia en metros del mapa
          const projection = view.getProjection();
          const metersPerUnit = projection.getMetersPerUnit();

          // Calcular la escala
          const scale = resolution * metersPerUnit * 39.37 * 72; // 39.37 pulgadas por metro, 72 puntos por pulgada

          if (markerOverlay) {

            if (scale >= maxZoomToShowLabels) {
              markerOverlay.getElement().style.display = 'none'; // Hide overlay


            } else {
              markerOverlay.getElement().style.display = 'block'; // Show overlay


            }
          }
          map.addOverlay(markerOverlay);

          var overlayElement = markerOverlay.getElement();
          overlayElement.style.zIndex = -1;

        });
      }


    });

    const vectorLayerPoint = new VectorLayer({
      source: vectorSourcePoint,
      zIndex: 2

    });
    vectorLayerPoint.set('id_data', item.id);
    map.addLayer(vectorLayerPoint);
    setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: vectorLayerPoint }]);
  }
  else{
    const clusterSource = new Cluster({
      distance: 40, // Distancia para agrupar puntos
      source: vectorSourcePoint,
    });

    const clusterLayer = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        const size = feature.get('features').length;
        console.log(size);
        let style;
        if (size > 1) {
          style = new Style({
            image: new Icon({
              src: item.icon, // Icono para el cluster
            }),
            text: new Text({
              text: size.toString(),
              offsetX:0,
              offsetY:30,
         
              font: 'bold 12px sans-serif',
              stroke: new Stroke({ color: '#ffffff', width: 3 }),
              fill: new Fill({
                color: item.color,
              }),
            }),
          });
        } else {
          // Si no está agrupado, usa un estilo diferente
          style = new Style({
            image: new Icon({
              src: item.icon, // Icono para un solo punto
            }),
          });
        }
        return style;
      },
    });

    clusterLayer.set('id_data', item.id);
    map.addLayer(clusterLayer);
    setAddedLayers(prevLayers => [...prevLayers, { url, vectorLayer: clusterLayer }]);
  }


  }
  const isCheckedItem = (value) => {
    return checkedItems.includes(value);
  };
  const handleToggleC = (item, value, url, icon, tipo, style) => () => {
    const isChecked = isCheckedItem(value);

    if (!item.children) {
      // Manejar caso sin hijos
      handleItemToggle(item, isChecked, value, url, icon, tipo, style);
    } else {
      handleItemToggle(item, isChecked, value, url, icon, tipo, style);

      if (!isChecked) {
        // Si el padre se está activando, activar solo los hijos que no estén activos
        item.children.forEach(child => {
          const childIsChecked = isCheckedItem(child.id);
          if (!childIsChecked) {
            handleToggleC(child, child.id, child.url, child.icon, child.tipo, child.style)();
          }
        });
      } else {
        // Si el padre se está desactivando, desactivar todos los hijos
        const childrenToDeactivate = item.children.filter(child => isCheckedItem(child.id));
        childrenToDeactivate.forEach(child => {
          handleToggleC(child, child.id, child.url, child.icon, child.tipo, child.style)();
        });
      }
    }
  };

  const handleItemToggle = (item, isChecked, value, url, icon, tipo, style) => {
    if (!isChecked) {
      checkedItems.push(value);
      addLayerByType(tipo, item);
    } else {
      checkedItems.splice(checkedItems.indexOf(value), 1);
      const layerToRemove = addedLayers.filter(layer => layer.url === url);
      
      if (layerToRemove) {
        layerToRemove.forEach(layer => {
          removeLayerAndFeatures(layer);
      });
   
        setAddedLayers(prevLayers => prevLayers.filter(layer => layer.url !== url));
      }
    }

  };

  const addLayerByType = (tipo, item) => {
    switch (tipo) {
      case 'point':
        addGeoJSONLayerP(item);
        break;
      case 'line':
        addGeoJSONLayerL(item);
        break;
      case 'poligono':
        addGeoJSONLayerPy(item);
        break;
      case 'mvt':
        addMVTLayer(item);
          break;
        
        break;
      default:
        break;
    }
  };

  const removeLayerAndFeatures = (layerToRemove) => {
    map.removeLayer(layerToRemove.vectorLayer);
    layerToRemove.vectorLayer.getSource().getFeatures().forEach(feature => {

      const featureOverlay = feature.get('overlay');
      if (featureOverlay) {
        map.removeOverlay(featureOverlay);
      }
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>



      <div style={{ width: '90%' }}>
        {items.map((parentItem, parentIndex) => (
          
          <Accordion elevation={0} key={parentIndex} disableGutters expanded={expanded[`panel${parentIndex + 1}`]} onChange={handleChange(`panel${parentIndex + 1}`)}>
 <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  sx={{ 
    boxShadow: "none", 
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, .125)' ,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
  aria-controls={`panel${parentIndex + 1}a-content`}
  id={`panel${parentIndex + 1}a-header`}
>
  <Typography 
    fontWeight={parentItem.highlight ? 'bold' : 'normal'} 
    sx={{ ml: 1, flexGrow: 1 }} // Esto asegura que el título ocupe el espacio disponible
  >
    {parentItem.title}
  </Typography>
  
  <Checkbox
    edge="end"
    checked={checkedItems.includes(parentItem.id)}
    tabIndex={-1}
    disabled={isDisabled}
    onChange={handleToggleC(parentItem, parentItem.id, parentItem.url, parentItem.icon, parentItem.tipo, parentItem.style)}
    onClick={(e) => e.stopPropagation()}
    onDoubleClick={(e) => e.stopPropagation()}
  />
</AccordionSummary>

            <AccordionDetails sx={{ marginTop: -1 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {parentItem.children.map((childItem, childIndex) => {
                  // Función de renderizado
                  const renderChildItem = () => {
                    if (!(childItem.childrenVisible)) {
                      return (
                        <div key={childIndex} style={{ display: 'flex', alignItems: 'center' }}>

                          <FormControlLabel
                            control={<Checkbox
                              edge="start"
                              checked={checkedItems.indexOf(childItem.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              onChange={handleToggleC(childItem, childItem.id, childItem.url, childItem.icon, childItem.tipo, childItem.style)}

                            />}
                            label={
                              <Grid container alignItems="center" justifyContent="center">
                                <Grid item>
                                  <IconLayer item={childItem} />
                                </Grid>
                                <Grid item>
                                  <Typography sx={{ marginLeft: '8px' }}>{childItem.title}</Typography>
                                </Grid>
                              </Grid>
                            }
                            labelPlacement="end" // Coloca el texto después del checkbox
                            sx={{ "& .MuiTypography-root": { marginLeft: "8px" } }}
                          />

                        </div>
                      );
                    }
                    else if ((childItem.childrenVisible)) {
                      return (

                        <Accordion sx={{ marginLeft: '-11px' }} elevation={0} key={`grand${childIndex + 1}`} disableGutters expanded={expanded[`panelchild${childIndex + 1}`]} onChange={handleChange(`panelchild${childIndex + 1}`)}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ boxShadow: "none", width: '100%', borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
                            aria-controls={`panelchild${childIndex + 1}a-content`}
                            id={`panelchild${childIndex + 1}a-header`}

                          >

                            <Checkbox
                              edge="start"
                              checked={checkedItems.indexOf(childItem.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              onChange={handleToggleC(childItem, childItem.id, childItem.url, childItem.icon, childItem.tipo, childItem.style)}
                              onClick={(e) => e.stopPropagation()}
                              id={childItem.id}
                            />
                            <Grid container alignItems="center" sx={{ marginLeft: '8px', marginRight: '8px' }}>
                              <Grid item>
                                <IconLayer item={childItem} />
                              </Grid>
                              <Grid item sx={{ marginLeft: '8px' }}>
                                <Typography sx={{ marginLeft: '8px' }}>{childItem.title}</Typography>
                              </Grid>
                            </Grid>

                          </AccordionSummary>
                          <AccordionDetails sx={{ marginTop: -1 }}  >
                            <div style={{ display: 'flex', flexDirection: 'column' }} id={`grandchild${childIndex + 1}a-header`}>

                              {childItem.children.map((grandchildItem, grandchildIndex) => (

                                <div key={`kgrand${grandchildIndex + 1}`} style={{ display: 'flex', alignItems: 'center' }}>

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        edge="start"

                                        checked={checkedItems.indexOf(grandchildItem.id) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={handleToggleC(grandchildItem, grandchildItem.id, grandchildItem.url, grandchildItem.icon, grandchildItem.tipo, grandchildItem.style)}

                                      />
                                    }
                                    id={grandchildItem.id}
                                    label={
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          <IconLayer item={grandchildItem} />
                                        </Grid>
                                        <Grid item>
                                          <Typography sx={{ marginLeft: '8px' }}>{grandchildItem.title}</Typography>
                                        </Grid>
                                      </Grid>
                                    }
                                    labelPlacement="end"

                                    sx={{ "& .MuiTypography-root": { marginLeft: "8px" } }}


                                  />
                                </div>
                              ))}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      );
                    }

                  };

                  // Llamada a la función de renderizado
                  return renderChildItem();
                })}

              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default GreenAccordion;
