import React from 'react';

import { useTheme } from "@mui/material/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  appBar: {
    height: 75,
    background: '#64122F',
    position: 'absolute',
    boxShadow: '0px 0px 8px 0px #00000033',
    top: 0,
    width: '100%',
    zIndex: 999,
  },
  logoContainerLeft: {
    height: '75px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoContainerRight: {
    height: '75px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '19px',
    textAlign: 'left',
  },
  logo: {
    height: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  marginSides: {
    marginLeft: '15px',
    marginRight: '15px',
  },
}));

function TopBar({ objeto }) {
  const classes = useStyles();
  const slogan = objeto.slogan;

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ paddingLeft: 0 }}>
        <Grid container justifyContent="flex-start">
          <Grid item className={classes.logoContainerLeft}>
            <img src='./img/logo_cusco.svg' alt="Logo MPC" className={classes.logo} />
          </Grid>
          <Grid item className={`${classes.logoContainerLeft} ${classes.marginSides}`}> {/* Añadimos margen a los lados */}
            <img src='./img/separate.svg' alt="Separador" className={classes.logo} />
          </Grid>
          <Grid item className={classes.logoContainerLeft}>
            <img src='./img/logo_py.svg' alt="Logo PROYECTO" className={classes.logo} />
          </Grid>
          <Grid item className={classes.logoContainerRight}>
            <label style={{ fontSize: '28px', textAlign: 'left' }}>{slogan}</label>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
