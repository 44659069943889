
import datav from '../data/layers_vraem.json';
import React, { useState } from 'react';

function JSONTableEditor() {
  const [data, setData] = useState(datav);

  const handleChange = (event, parentId, field, childId = null) => {
    const newData = data.map(parent => {
      if (parent.id === parentId) {
        if (childId !== null) {
          return {
            ...parent,
            children: parent.children.map(child => {
              if (child.id === childId) {
                return {
                  ...child,
                  [field]: event.target.value
                };
              }
              return child;
            })
          };
        } else {
          return {
            ...parent,
            [field]: event.target.value
          };
        }
      }
      return parent;
    });
    setData(newData);
  };

  const renderChildren = (parentId, children) => {
    if (!children || children.length === 0) return null;

    return (
      <ul>
        {children.map(child => (
          <li key={child.id}>
            <label>ID:</label>
            <input
              type="text"
              value={child.id}
              onChange={(event) => handleChange(event, parentId, 'id', child.id)}
            />
            <label>Title:</label>
            <input
              type="text"
              value={child.title}
              onChange={(event) => handleChange(event, parentId, 'title', child.id)}
            />
            <label>Content:</label>
            <input
              type="text"
              value={child.content}
              onChange={(event) => handleChange(event, parentId, 'content', child.id)}
            />
            <label>Icon:</label>
            <input
              type="text"
              value={child.icon}
              onChange={(event) => handleChange(event, parentId, 'icon', child.id)}
            />
            <label>URL:</label>
            <input
              type="text"
              value={child.url}
              onChange={(event) => handleChange(event, parentId, 'url', child.id)}
            />
            <label>Tipo:</label>
            <input
              type="text"
              value={child.tipo}
              onChange={(event) => handleChange(event, parentId, 'tipo', child.id)}
            />
            {/* Agrega etiquetas de título y campos para otros atributos aquí */}
            {renderChildren(parentId, child.children)}
          </li>
        ))}
      </ul>
    );
  };
  const handleSave = () => {
    // Función para guardar los datos modificados en un nuevo JSON
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'modified_data.json');
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div>
      <h1>JSON Editor</h1>
      <ul>
        {data.map(parent => (
          <li key={parent.id}>
            <label>ID:</label>
            <input
              type="text"
              value={parent.id}
              onChange={(event) => handleChange(event, parent.id, 'id')}
            />
            <label>Title:</label>
            <input
              type="text"
              value={parent.title}
              onChange={(event) => handleChange(event, parent.id, 'title')}
            />
            <label>Highlight:</label>
            <input
              type="checkbox"
              checked={parent.highlight}
              onChange={(event) => handleChange(event, parent.id, 'highlight')}
            />
            <label>Content:</label>
            <input
              type="text"
              value={parent.content}
              onChange={(event) => handleChange(event, parent.id, 'content')}
            />
            {/* Agrega etiquetas de título y campos para otros atributos aquí */}
            {renderChildren(parent.id, parent.children)}
          </li>
        ))}
      </ul>
      <button onClick={handleSave}>Guardar cambios</button>
    </div>
  );
}

export default JSONTableEditor;
