import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Capas = ({ data }) => {
  const handleClose = () => {
    // Lógica para manejar el cierre
  };

  return (
    <Box
    sx={{
      backgroundColor: '#64122F',
      borderRadius: '8px',
      padding: '0 12px', // Ajuste del padding para que quepa el contenido sin incrementar la altura
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      margin: '10px',
      height: '40px', // Altura fija de 40px
      color:'#ffffff'
    }}
  >
    <Typography variant="h6" component="span" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
      CAPAS
    </Typography>
    <IconButton
      onClick={handleClose}
      sx={{
        padding: '4px',
        color: 'black',
      }}
    >
      <CloseIcon />
    </IconButton>
  </Box>
  
  );
};

export default Capas;
