
import { lightenColor } from './ext/ColorUtils'

const IconLayer = (item) => {
    const data=item.item;
   
    return (
        <div style={{ marginLeft: 'auto' }}>
            {data.icon && data.tipo === 'line' ? (
                // Si item.tipo es 'line', carga el SVG de la línea
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line className="line" x1="22.981" y1="4.88909" x2="3.88913" y2="23.981" stroke={data.style.color} strokeWidth="3" />
                </svg>
            ) : data.icon && data.tipo !== 'line' ? (
                // Si item.tipo no es 'line', carga la imagen
                <img src={data.icon} alt="Custom Icon" style={{ width: 24, height: 24 }} />
            ) : data.tipo === "point" ? (
                // Si item.tipo es 'point', carga el SVG de un círculo

                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.147 12C23.147 18.0751 18.2221 23 12.147 23C6.07183 23 1.14697 18.0751 1.14697 12C1.14697 5.92487 6.07184 1 12.147 1C18.2221 1 23.147 5.92487 23.147 12Z"
                 fill={lightenColor(data.style.color, 0.4) } stroke={data.style.color} stroke-width="2"/>
                </svg>



            
            ) : data.tipo === "poligono" ? (
                // Si item.tipo es 'poligono', carga el SVG de un rectángulo
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <rect x="2" y="2" width="20" height="20" fill={data.style.color} />
                </svg>
            ) : null}
        </div>
    );
};

export default IconLayer;
